import classNames from "classnames"
import React from "react"

import { Paragraph2, Title5 } from "@bounce/design-system"
import type { Nullable } from "@bounce/util"

import type { PNImageProps } from "../PNImage/PNImage"
import { PNImage } from "../PNImage/PNImage"

export type SlideProps = {
  src: PNImageProps["src"]
  title?: Nullable<string>
  description?: Nullable<string> | React.ReactNode
  action?: Nullable<React.ReactNode>
  imageClassName?: string
  titleLevel?: 3 | 4
}

export const Slide = ({ title, src, description, action, imageClassName, titleLevel = 3 }: SlideProps) => (
  <div className="flex w-full flex-col">
    <PNImage
      alt={title}
      src={src}
      height={140}
      width={190}
      imgixParams={{ fit: "max", height: 140 }}
      loading="lazy"
      className={classNames("h-[116px] object-contain object-left", imageClassName)}
    />
    {!!title && (
      <Title5 level={titleLevel} className="mt-3 text-black">
        {title}
      </Title5>
    )}
    {!!description && <Paragraph2 className="mt-3 text-neutral-800">{description}</Paragraph2>}
    {!!action && action}
  </div>
)
