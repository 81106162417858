import classNames from "classnames"
import Image from "next/image"
import React from "react"

import { useTranslation } from "@bounce/i18n"

import { CityList } from "./CityList"
import { useSectionViewed } from "../../../../../hooks/useSectionViewed"
import type { CityLink } from "../../../../../libs/bounce/cities"

import illustration from "../../../../../assets/img/hands-free-travel.png"

type CitiesProps = {
  cities: CityLink[]
  title?: string
  className?: string
}

export const Cities = ({ cities, title, className }: CitiesProps) => {
  const { t } = useTranslation("marketing-ls-home")
  const { sectionRef } = useSectionViewed({ name: "Cities" })

  return (
    <section ref={sectionRef} className={classNames(className, "w-full py-section")}>
      <div className="relative mx-auto w-full max-w-screen-xl px-6">
        <h2 className="title2 pr-24 text-black md:max-w-[600px]">
          {title || t("screen.homeScreen.cities.titleV3", "Store your luggage all over the world")}
        </h2>
        <CityList cities={cities} className="mt-16" />
        <Image
          src={illustration}
          width={163}
          height={163}
          loading="lazy"
          alt={t("screen.homeScreen.cities.altV1", "Hands free travel, Around the globe")}
          className="absolute right-0 top-[-60px] mr-6 size-[95px] md:top-[-18px] md:size-[123px]"
        />
      </div>
    </section>
  )
}
