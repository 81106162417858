import React from "react"

import { useTranslation } from "@bounce/i18n"

import { HeroContainer } from "./HeroContainer"
import { AppAggregateRating } from "@/components/LuggageStorage/AppAggregateRating/AppAggregateRating"
import { Marquee } from "@/components/LuggageStorage/Marquee/Marquee"
import { SearchBar } from "@/components/LuggageStorage/SearchBar/SearchBar"
import type { GenericInterpolations } from "@/hooks/useGenericInterpolations"
import type { AggregateRating } from "@/libs/bounce/types"

type HeroProps = {
  interpolations: GenericInterpolations
  aggregateRating: AggregateRating
}

export const Hero = ({ interpolations, aggregateRating }: HeroProps) => {
  const { t } = useTranslation("marketing-ls-home")

  return (
    <>
      <HeroContainer>
        <AppAggregateRating />
        <h1 className="title1 mb-2 break-words text-black md:mb-6">
          {t("screen.homeNextScreen.hero.titleV4", "Luggage storage near you")}
        </h1>
        <p className="text-base text-black md:text-xl xl:text-2xl">
          {t("screen.homeNextScreen.hero.subtitleV8", {
            defaultValue: "Freedom to explore from {{price}} / day",
            price: interpolations.PRICE,
          })}
        </p>
        <SearchBar ctaName="SearchBarCTAHome" showPickers className="mt-6 lg:mt-[72px]" />
      </HeroContainer>
      <Marquee price={interpolations.PRICE} aggregateRating={aggregateRating} className="mb-24" />
    </>
  )
}
