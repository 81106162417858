import { useTranslation } from "@bounce/i18n"
import { Dropdown, DropdownItem } from "@bounce/web-components"

import type { UsePricingSelectorOption } from "./usePricingSelector"

type CountrySelectorProps = {
  options: UsePricingSelectorOption[]
  selectedOption?: UsePricingSelectorOption
  onChange: (countryCode: string) => void
  className?: string
}

export const CountrySelector = ({ options, selectedOption, onChange, className }: CountrySelectorProps) => {
  const { t } = useTranslation()

  return (
    <Dropdown
      id="country-selector"
      buttonClassName="text-white bg-transparent"
      label={t("cmp.pricingLuggageStorageNext.priceIn", "Price in {{country}}", {
        country: selectedOption?.countryName,
      })}
      className={className}>
      {options.map(({ countryCode, countryName }) => (
        <DropdownItem as="button" key={countryCode} onClick={() => onChange(countryCode)} suppressHydrationWarning>
          {countryName}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}
