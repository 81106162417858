import type { RTLinkNode } from "@prismicio/types"

import Datadog from "@bounce/datadog"
import { DateTime } from "@bounce/date"

export type Interpolations = {
  [key: string]: string
}

const defaultInterpolations: Interpolations = {
  YEAR: DateTime.now().year.toString(),
  // @deprecated
  CURRENT_YEAR: DateTime.now().year.toString(),
}

/**
 * Interpolates text with the given placeholders.
 * @example interpolateText("Hey {{NAME}}!", { NAME: "John" }) => "Hey John!"
 */
export const interpolateText = <T>(text: T, interpolations: Interpolations = {}): T => {
  const placeholders = {
    ...defaultInterpolations,
    ...interpolations,
  }

  if (Array.isArray(text)) {
    return text.map(t => interpolateText(t, placeholders)) as T
  }

  if (typeof text !== "string") {
    return text
  }

  let newText: string = text
  Object.keys(placeholders).forEach(k => {
    const value = placeholders[k]
    if (value) {
      newText = newText.replace(new RegExp(`\\{\\{?\\s*${k}\\s*\\}\\}?`, "gi"), value)
    }
  })

  const unreplacedVariables = newText.match(/\{\{\s*\w+\s*\}\}/g)
  if (unreplacedVariables) {
    void Datadog.addError(`Interpolation: Unreplaced variables in text ${unreplacedVariables.join(", ")}`)
  }

  return newText as T
}

/**
 * Interpolates the href of a link node with the given placeholders.
 * @example interpolateLink({ data: { url: "https://example.com/{{NAME}}" } }, { NAME: "John" }) => { data: { url: "https://example.com/John" } }
 */
export const interpolateLink = (node: RTLinkNode, interpolations: Interpolations = {}): RTLinkNode => {
  node.data.url = interpolateText(node.data.url, interpolations)

  return node
}
